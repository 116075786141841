import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { CartContext } from '../../context/shopContext'
import fbq from '../../helpers/fbq'
import twq from '../../helpers/twq'
import { trackUpsellModule } from '../../lib/ga4'
import Link from '../Link'
import discountPercentageList from '../../helpers/discountPercentage'

function hexToRgb(hex) {
  try {
    if (!hex) return [0, 0, 0]
    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  } catch (e) {
    return [0, 0, 0]
  }
}

function rgbToHsv(r, g, b) {
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return [h, s, v];
}
const UpsellContainer = styled.div`
  overflow-x: hidden;
  & .slick-prev, .slick-next {
    top: 60%;
  }
  & .slick-prev {
    left: 5px;
    z-index: 999;
  }
  & .slick-next {
    right: 10px;
    z-index: 999;
  }

  .slick-next:before {
    color: black;
  }

  .slick-prev:before {
    color: black;
  }

  & .slick-dots {
    bottom: 0;
    position: static;
    color: white;
  }

  & .slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: white;
  }

  @media (min-width: 1026px) {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    .slick-prev:before, .slick-next:before {
      color: grey;
    }
    .slick-slider {
      max-width: 425px;
    }
  }
  @media (max-width: 1025px) {
    justify-content: center;
    display: flex;

    .slick-prev:before, .slick-next:before {
      color: grey;
    }
    & .slick-prev {
      left: 10px;
    }
    & .slick-prev, .slick-next {
      top: 60%;
    }
    .slick-slider {
      max-width: 85vw;
    }
    & .slick-list {
      max-width: 340px;
    }
    padding-bottom: 48px;
    .slick-slide {
      padding: 0 5px;
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
    display: flex;
    .slick-prev:before, .slick-next:before {
      color: grey;
    }
    .slick-slider {
      max-width: 85vw;
    }
  }
`;
const CompleteText = styled.div`
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgb(var(--terracota));
  margin-bottom: 16px;
  margin-top: 24px;
`
const GreenButtonInline = styled.button`
  outline: none;
  min-width: 29%;
  height: 45px;
  // border-radius: 34.5px;
  margin-top: 10px;
  background: white;
  display: block;
  cursor: pointer;
  padding: 15px 12px;
  font-family: Objectivity;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0.66px;
  text-align: center;

  transition: all 0.1s;
  text-transform: uppercase;
  padding: 8px 24px;
  margin-top: 6;
  width: 100%;
  // border-radius: 50px;
  height: 50;
  background-color: #c6c5c4;
  color: #000;
  // border-radius: 41.5px;
  border: solid 1px #000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--black));
    color: rgb(var(--black));
  }
`
const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const TextContainer = styled.div`
  flex-grow: 1;
`
const TextTop = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.41px;
  color: rgba(0, 0, 0, 0.85);
`
const TextBottom = styled.div`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.21px;
  color: rgba(0, 0, 0, 0.85);
`
const ImageContainer = styled.div`
  height: 160px;
  aspect-ratio: 1;
  display: flex;
  mix-blend-mode: darken;
  margin-right: 16px;
  @media (max-width: 768px) {
    height: 120px;
  }
`
const ColorSelectorContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ColorSelectorCircleContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
`
const BottomText = styled.div`
  text-transform: uppercase;
  font-family: Objectivity;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgb(var(--terracota));
  margin-top: 24px;

  & p {
    display: inline !important;
  }
`
const IconImage = styled(GatsbyImage)`
  width: 100%;
`
const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // border-radius: 32px;
  padding: 20px 10px;
  overflow: hidden;
  background: #F7F6F4;
  // margin: 0px 5px;

  // &:hover {
  //   box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  // }
`
const ColorSelector = ({ selectedColor, setSelectedColor, colors }) => {
  const size = 16
  const Circle = styled.span`
    display: inline-block;
    min-width: ${size}px;
    min-height: ${size}px;
    border-radius: 50%;

    margin: 2px 4px;
    margin-left: 0;
    ${(props) => (props.selected ? `border: 1px solid #979797;` : ``)}
    background-color: ${(props) => props.color};
    overflow: hidden;
    cursor: pointer;
  `
  return colors?.map((color, key) => (
    <Fragment key={key}>
      <Circle
        key={key}
        onClick={() => {
          return setSelectedColor(key)
        }}
        color={color.replace('545454', '0c0c0c').replace('4B4E5C', '324058')}
        black={color === '#E6E6E6'}
        selected={color === selectedColor}
      />
    </Fragment>
  ))
}

const UpsellHeading = styled.div`
  font-size: 18px;
  line-height: 1.39;
  font-weight: 500;
  text-align: center;
  color: white;
`
const UpsellSubheading = styled.div`
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
`
const ArrowImage = styled.img`
  &:hover {
    transition: filter 0.3s ease;
    filter: opacity(0.6); /* Reduce brightness to 80% */
  }

  &:active {
    transition: filter 0.3s ease;
    filter: opacity(0.1);
  }
`

export const CartAddToCartModules = ({
  refresh,
  product,
  hideBundlePricing,
  heading,
  subheading,
  returnAddToCart,
  addToCartText,
  discount,
  selectedVariantColour,
  shared
}) => {
  let cleanProduct = cleanUpProduct(product)
  const context = useContext(CartContext)
  const { addToCart, currencyPrefix, currencyCode, storeLocale } = context

  const [selectedColourState, setSelectedColour] = useState(undefined)
  const findColour = cleanProduct?.Variants?.findIndex(val => val.ColourName === selectedVariantColour)
  const selectedColour = selectedColourState !== undefined ? selectedColourState : (findColour !== -1 ? findColour : 0)
  const [showContainer, setShowContainer] = useState(true)
  let currencySymbol = currencyPrefix.match(/[^a-z0-9]/gi);  // Find all symbols
  let currency = currencyPrefix.match(/[a-z0-9]/gi);  // Find all letters and numbers
  const discountPercentage = shared?.discountPromoOn ? discountPercentageList[storeLocale]?.[cleanProduct?.Variants?.[selectedColour]?.SKU]?.amount : 0

  if (discount) {
    cleanProduct['discount'] = discount
  }

  if (discountPercentage) {
    cleanProduct['discount'] = cleanProduct.Variants[selectedColour]?.shopifyInfo?.price * discountPercentage / 100
  }

  useEffect(() => {
    setShowContainer(true)
  }, [, refresh])

  const handleAddPacking = async () => {
    const selectedVariant = cleanProduct.Variants[selectedColour]

    if (returnAddToCart) {
      returnAddToCart({
        title: cleanProduct.Title,
        variantQuantity: 1,
        id: selectedVariant.SKU,
        upsellItem: true,
        globalProduct: {
          [storeLocale]: {
            id: selectedVariant.shopifyInfo.shopifyId,
            sku: selectedVariant.SKU,
            image: selectedVariant.shopifyInfo.image,
            price: selectedVariant.shopifyInfo.price,
            title: selectedVariant.ColourName,
          },
        },
      })
      return
    }
    setShowContainer(false)

    try {
      fbq('AddToCart', {
        content_name: cleanProduct.Title,
        content_ids: `shopify_${cleanProduct?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', '')}-${selectedVariant?.shopifyInfo?.shopifyId?.replace('gid://shopify/ProductVariant/', '')}`,
        content_type: 'product',
        value: selectedVariant.shopifyInfo.price,
        currency: currencyCode,
      })
    } catch (e) {
      console.log(e)
    }

    try {
      ttq?.track('AddToCart', {
        contents: [
          {
            content_id: selectedVariant.shopifyInfo.shopifyId,
            content_name: cleanProduct.Title,
            content_category: 'product',
            brand: 'July',
            quantity: 1,
            price: selectedVariant.shopifyInfo.price,
            currency: currencyCode,
          }
        ]
      })
    } catch (e) {
      console.log(e)
    }

    twq('tw-ompvm-omqqp', {
      contents: [
        { content_name: cleanProduct.Title },
        { content_id: selectedVariant.shopifyInfo.shopifyId },
      ],
      value: selectedVariant.shopifyInfo.price,
      currency: currencyCode,
    })

    addToCart({
      title: cleanProduct.Title,
      variantQuantity: 1,
      id: selectedVariant.SKU,
      upsellItem: true,
      globalProduct: {
        [storeLocale]: {
          id: selectedVariant.shopifyInfo.shopifyId,
          sku: selectedVariant.SKU,
          image: selectedVariant.shopifyInfo.image,
          price: selectedVariant.shopifyInfo.price,
          title: selectedVariant.ColourName,
        },
      },
    })

    const trackingData = { item_id: selectedVariant.SKU, item_name: selectedVariant.ColourName, price: selectedVariant.shopifyInfo.price, quantity: 1 }
    trackUpsellModule({ items: trackingData, value: selectedVariant.shopifyInfo.price, currency: currencyCode })
  }

  if (!cleanProduct || !cleanProduct.Variants || cleanProduct.Variants.length === 0) {
    return <></>
  }
  return (
    <div>
      {/* {
        heading && (
          <>
            <UpsellHeading>Bundle & Save</UpsellHeading>
            <div style={{ height: 16 }} />
          </>
        )
      } */}
      {
        heading && (
          <UpsellHeading style={{ color: '#000' }}>{heading}</UpsellHeading>
        )
      }
      {
        subheading && (
          <UpsellSubheading>{subheading}</UpsellSubheading>
        )
      }
      {
        (heading || subheading) && <div style={{ height: 16 }} />
      }
      <CardContainer
        className={
          showContainer
            ? 'animate__animated animate__fadeInRight'
            : 'animated fadeOutRight'
        }
      >
        <ItemContainer>
          <Link to={cleanProduct?.handle}>
            <ImageContainer>
              {cleanProduct.Variants[selectedColour]?.ShopTileImage?.file &&
                <IconImage objectFit="contain" image={getImage(cleanProduct.Variants[selectedColour]?.ShopTileImage?.file)} alt={cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== null && cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== undefined ? cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText : cleanProduct.Variants[selectedColour]?.ShopTileImage?.name} />
              }
            </ImageContainer>
          </Link>
          <TextContainer
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Link to={cleanProduct?.handle}>
              <TextTop>{cleanProduct.Title}</TextTop>
            </Link>
            <span style={{ fontSize: '0.7em' }}>
              {(cleanProduct?.discount && !hideBundlePricing) ? (
                <span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      marginRight: 6,
                      justifyContent: 'normal'
                    }}
                  >
                    {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                  </span>
                  <span>
                    {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount}{" "}{currency}
                  </span>
                </span>
              ) : (
                <span>
                  {
                    discountPercentage > 0 ? (
                      <span>
                        <span
                          style={{
                            textDecoration: 'line-through',
                            marginRight: 6,
                            justifyContent: 'normal'
                          }}
                        >
                          {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                        </span>
                        <span style={{ fontWeight: 600 }}>
                          {
                            cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount === 0 ? (
                              <span>FREE</span>
                            ) : (
                              <span>{currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount}{" "}{currency}</span>
                            )
                          }
                        </span>
                      </span>
                    ) : (
                      <>
                        {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                      </>
                    )
                  }
                </span>
              )}
            </span>
            <TextBottom>
              COLOUR : {' '}
              <span style={{ display: 'inline-block' }}>
                {cleanProduct.Variants[selectedColour]?.ColourName}
              </span>
            </TextBottom>
            <ColorSelectorCircleContainer>
              <ColorSelector
                selectedColor={cleanProduct.Variants[selectedColour]?.Colour}
                setSelectedColor={setSelectedColour}
                colors={cleanProduct.Variants.map((val) => val.Colour)}
              />
            </ColorSelectorCircleContainer>
            <ColorSelectorContainer>
              <GreenButtonInline
                onClick={handleAddPacking}
              >
                <div style={{ display: 'flex', textAlign: 'center', fontWeight: 400 }}>
                  {/* <img src={addtocartIcon} alt={addtocartIcon} width={20} /> */}
                  {addToCartText ? addToCartText : 'Add To Cart'}
                </div>
              </GreenButtonInline>
            </ColorSelectorContainer>
          </TextContainer>
        </ItemContainer>
      </CardContainer>
    </div>
  )
}

export const UpsellGift = ({
  refresh,
  product,
  hideBundlePricing,
  returnAddToCart,
  addToCartText,
  discount,
  selectedVariantColour,
}) => {
  let cleanProduct = cleanUpProduct(product)
  if (discount) {
    cleanProduct['discount'] = discount
  }
  const context = useContext(CartContext)
  const { addToCart, currencyPrefix, currencyCode, storeLocale } = context

  const [selectedColourState, setSelectedColour] = useState(undefined)
  const findColour = cleanProduct?.Variants?.findIndex(val => val.ColourName === selectedVariantColour)
  const selectedColour = selectedColourState !== undefined ? selectedColourState : (findColour !== -1 ? findColour : 0)
  const [showContainer, setShowContainer] = useState(true)
  let currencySymbol = currencyPrefix.match(/[^a-z0-9]/gi);  // Find all symbols
  let currency = currencyPrefix.match(/[a-z0-9]/gi);  // Find all letters and numbers

  const upsellGiftPriceLookUp = {
    'en': '$150',
    'en-NZ': '$150',
    'en-GB': '£150',
    'default': '$350'
  };
  const upsellGiftPrice = upsellGiftPriceLookUp[storeLocale] || upsellGiftPriceLookUp['default'];

  useEffect(() => {
    setShowContainer(true)
  }, [, refresh])

  const handleAddPacking = async () => {
    const selectedVariant = cleanProduct.Variants[selectedColour]

    if (returnAddToCart) {
      returnAddToCart({
        title: cleanProduct.Title,
        variantQuantity: 1,
        id: selectedVariant.SKU,
        upsellItem: true,
        globalProduct: {
          [storeLocale]: {
            id: selectedVariant.shopifyInfo.shopifyId,
            sku: selectedVariant.SKU,
            image: selectedVariant.shopifyInfo.image,
            price: selectedVariant.shopifyInfo.price,
            title: selectedVariant.ColourName,
          },
        },
      })
      return
    }
    setShowContainer(false)

    try {
      fbq('AddToCart', {
        content_name: cleanProduct.Title,
        content_ids: `shopify_${cleanProduct?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', '')}-${selectedVariant?.shopifyInfo?.shopifyId?.replace('gid://shopify/ProductVariant/', '')}`,
        content_type: 'product',
        value: selectedVariant.shopifyInfo.price,
        currency: currencyCode,
      })
    } catch (e) {
      console.log(e)
    }

    try {
      ttq?.track('AddToCart', {
        contents: [
          {
            content_id: selectedVariant.shopifyInfo.shopifyId,
            content_name: cleanProduct.Title,
            content_category: 'product',
            brand: 'July',
            quantity: 1,
            price: selectedVariant.shopifyInfo.price,
            currency: currencyCode,
          }
        ]
      })
    } catch (e) {
      console.log(e)
    }

    twq('tw-ompvm-omqqp', {
      contents: [
        { content_name: cleanProduct.Title },
        { content_id: selectedVariant.shopifyInfo.shopifyId },
      ],
      value: selectedVariant.shopifyInfo.price,
      currency: currencyCode,
    })

    addToCart({
      title: cleanProduct.Title,
      variantQuantity: 1,
      id: selectedVariant.SKU,
      upsellItem: true,
      globalProduct: {
        [storeLocale]: {
          id: selectedVariant.shopifyInfo.shopifyId,
          sku: selectedVariant.SKU,
          image: selectedVariant.shopifyInfo.image,
          price: selectedVariant.shopifyInfo.price,
          title: selectedVariant.ColourName,
        },
      },
    })

    const trackingData = { item_id: selectedVariant.SKU, item_name: selectedVariant.ColourName, price: selectedVariant.shopifyInfo.price, quantity: 1 }
    trackUpsellModule({ items: trackingData, value: selectedVariant.shopifyInfo.price, currency: currencyCode })
  }

  if (!cleanProduct || !cleanProduct.Variants || cleanProduct.Variants.length === 0) {
    return <></>
  }
  return (
    <div>
      <UpsellHeading>Olympics offer.</UpsellHeading>
      <UpsellHeading style={{ fontSize: 13, lineHeight: 2, paddingBottom: 12 }}>Add your Olympics Drink Bottle for free.</UpsellHeading>
      <CardContainer
        className={
          showContainer
            ? 'animate__animated animate__fadeInRight'
            : 'animated fadeOutRight'
        }
      >
        <ItemContainer>
          <ImageContainer style={{ height: 128 }}>
            {cleanProduct.Variants[selectedColour]?.ShopTileImage?.file &&
              <IconImage objectFit="contain" image={getImage(cleanProduct.Variants[selectedColour]?.ShopTileImage?.file)} alt={cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== null && cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== undefined ? cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText : cleanProduct.Variants[selectedColour]?.ShopTileImage?.name} />
            }
          </ImageContainer>
          <TextContainer
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <TextTop>{cleanProduct.Title}</TextTop>
            <span style={{ fontSize: '0.7em' }}>
              {(cleanProduct?.discount && !hideBundlePricing) ? (
                <span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      marginRight: 6,
                      justifyContent: 'normal'
                    }}
                  >
                    {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                  </span>
                  <span style={{ fontWeight: 600 }}>
                    {
                      cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount === 0 ? (
                        <span>FREE</span>
                      ) : (
                        <span>{currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount}{" "}{currency}</span>
                      )
                    }
                  </span>
                </span>
              ) : (
                <span>
                  {
                    discount > 0 ? (
                      <span>
                        <span
                          style={{
                            textDecoration: 'line-through',
                            marginRight: 6,
                            justifyContent: 'normal'
                          }}
                        >
                          {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                        </span>
                        <span style={{ fontWeight: 600 }}>
                          {
                            cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount === 0 ? (
                              <span>FREE</span>
                            ) : (
                              <span>{currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount}{" "}{currency}</span>
                            )
                          }
                        </span>
                      </span>
                    ) : (
                      <>
                        {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                      </>
                    )
                  }
                </span>
              )}
            </span>
            <TextBottom>
              TYPE : {' '}
              <span style={{ display: 'inline-block' }}>
                {cleanProduct.Variants[0]?.ColourName.toUpperCase()}
              </span>
            </TextBottom>
            <ColorSelectorContainer style={{ alignItems: 'start' }}>
              <GreenButtonInline
                style={{ width: '95%' }}
                onClick={handleAddPacking}
              >
                <div style={{ display: 'flex', textAlign: 'center', fontWeight: 400 }}>
                  {'ADD FREE GIFT'}
                </div>
              </GreenButtonInline>
            </ColorSelectorContainer>
          </TextContainer>
        </ItemContainer>
        <TextBottom style={{ fontSize: 9, textAlign: 'center', marginTop: '10px' }}>
          Eligible while stocks last. No returns or exchanges
        </TextBottom>
      </CardContainer>
    </div>
  )
}

export const WeekenderDealModule = ({
  refresh,
  product,
  hideBundlePricing,
  returnAddToCart,
  addToCartText,
  discount,
  selectedVariantColour
}) => {
  let cleanProduct = cleanUpProduct(product)

  if (discount) {
    cleanProduct['discount'] = discount
  }

  const context = useContext(CartContext)
  const { addToCart, currencyPrefix, currencyCode, storeLocale } = context
  const [selectedColourState, setSelectedColour] = useState(undefined)
  const findColour = cleanProduct?.Variants?.findIndex(val => val.ColourName === selectedVariantColour)
  const selectedColour = selectedColourState !== undefined ? selectedColourState : (findColour !== -1 ? findColour : 0)
  const [showContainer, setShowContainer] = useState(true)
  let currencySymbol = currencyPrefix.match(/[^a-z0-9]/gi);  // Find all symbols
  let currency = currencyPrefix.match(/[a-z0-9]/gi);  // Find all letters and numbers

  useEffect(() => {
    setShowContainer(true)
  }, [, refresh])

  const weekenderPriceLookUp = {
    'en-AU': '$100',
    'en-NZ': '$150',
    'en': '$80',
    'en-GB': '£100',
    'default': '$100'
  };
  const weekenderPrice = weekenderPriceLookUp[storeLocale] || weekenderPriceLookUp['default'];

  const handleAddPacking = async () => {
    const selectedVariant = cleanProduct.Variants[selectedColour]

    if (returnAddToCart) {
      returnAddToCart({
        title: cleanProduct.Title,
        variantQuantity: 1,
        id: selectedVariant.SKU,
        upsellItem: true,
        globalProduct: {
          [storeLocale]: {
            id: selectedVariant.shopifyInfo.shopifyId,
            sku: selectedVariant.SKU,
            image: selectedVariant.shopifyInfo.image,
            price: selectedVariant.shopifyInfo.price,
            title: selectedVariant.ColourName,
          },
        },
      })
      return
    }
    setShowContainer(false)

    try {
      fbq('AddToCart', {
        content_name: cleanProduct.Title,
        content_ids: `shopify_${cleanProduct?.shopifyInfo?.shopifyId?.replace('gid://shopify/Product/', '')}-${selectedVariant?.shopifyInfo?.shopifyId?.replace('gid://shopify/ProductVariant/', '')}`,
        content_type: 'product',
        value: selectedVariant.shopifyInfo.price,
        currency: currencyCode,
      })
    } catch (e) {
      console.log(e)
    }

    try {
      ttq?.track('AddToCart', {
        contents: [
          {
            content_id: selectedVariant.shopifyInfo.shopifyId,
            content_name: cleanProduct.Title,
            content_category: 'product',
            brand: 'July',
            quantity: 1,
            price: selectedVariant.shopifyInfo.price,
            currency: currencyCode,
          }
        ]
      })
    } catch (e) {
      console.log(e)
    }

    twq('tw-ompvm-omqqp', {
      contents: [
        { content_name: cleanProduct.Title },
        { content_id: selectedVariant.shopifyInfo.shopifyId },
      ],
      value: selectedVariant.shopifyInfo.price,
      currency: currencyCode,
    })

    addToCart({
      title: cleanProduct.Title,
      variantQuantity: 1,
      id: selectedVariant.SKU,
      upsellItem: true,
      globalProduct: {
        [storeLocale]: {
          id: selectedVariant.shopifyInfo.shopifyId,
          sku: selectedVariant.SKU,
          image: selectedVariant.shopifyInfo.image,
          price: selectedVariant.shopifyInfo.price,
          title: selectedVariant.ColourName,
        },
      },
    })

    const trackingData = { item_id: selectedVariant.SKU, item_name: selectedVariant.ColourName, price: selectedVariant.shopifyInfo.price, quantity: 1 }
    trackUpsellModule({ items: trackingData, value: selectedVariant.shopifyInfo.price, currency: currencyCode })
  }

  if (!cleanProduct || !cleanProduct.Variants || cleanProduct.Variants.length === 0) {
    return <></>
  }
  return (
    <div>
      <UpsellHeading>Limited Time Offer.</UpsellHeading>
      <UpsellHeading style={{ fontSize: 13, lineHeight: 2 }}>Add on a Weekender from {weekenderPrice}.</UpsellHeading>
      <CardContainer
        className={
          showContainer
            ? 'animate__animated animate__fadeInRight'
            : 'animated fadeOutRight'
        }
      >
        <ItemContainer>
          <ImageContainer style={{ display: 'block' }}>
            {cleanProduct.Variants[selectedColour]?.ShopTileImage?.file &&
              <IconImage objectFit="contain" image={getImage(cleanProduct.Variants[selectedColour]?.ShopTileImage?.file)} alt={cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== null && cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText !== undefined ? cleanProduct.Variants[selectedColour]?.ShopTileImage?.alternativeText : cleanProduct.Variants[selectedColour]?.ShopTileImage?.name} />
            }
          </ImageContainer>
          <TextContainer
            style={{
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'column',
            }}
          >
            <TextTop>{cleanProduct.Title}</TextTop>
            <span style={{ fontSize: 14, marginTop: 10, letterSpacing: '-0.8px' }}>
              {(cleanProduct?.discount && !hideBundlePricing) ? (
                <span>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      marginRight: 6,
                      justifyContent: 'normal'
                    }}
                  >
                    {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                  </span>
                  <span style={{ fontWeight: 600 }}>
                    {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price - cleanProduct.discount}{" "}{currency}
                  </span>
                </span>
              ) : (
                <span style={{ fontWeight: 600 }}>
                  {currencySymbol}{cleanProduct.Variants[selectedColour]?.shopifyInfo?.price}{" "}{currency}
                </span>
              )}
            </span>
            <div>
              <TextBottom>
                COLOUR : {' '}
                <span style={{ display: 'inline-block' }}>
                  {cleanProduct.Variants[selectedColour]?.ColourName}
                </span>
              </TextBottom>
              <ColorSelectorCircleContainer>
                <ColorSelector
                  selectedColor={cleanProduct.Variants[selectedColour]?.Colour}
                  setSelectedColor={setSelectedColour}
                  colors={cleanProduct.Variants.map((val) => val.Colour)}
                />
              </ColorSelectorCircleContainer>
            </div>
            <ColorSelectorContainer>
              <GreenButtonInline
                onClick={handleAddPacking}
              >
                <div style={{ display: 'flex', textAlign: 'center', fontWeight: 400 }}>
                  {/* <img src={addtocartIcon} alt={addtocartIcon} width={20} /> */}
                  {addToCartText ? addToCartText : 'Add To Cart'}
                </div>
              </GreenButtonInline>
            </ColorSelectorContainer>
          </TextContainer>

        </ItemContainer>
        <TextBottom style={{ textDecoration: 'underline', fontSize: 9, textAlign: 'center', marginTop: '10px' }}>
          <Link to={'weekender-promo'} style={{ display: 'inline-block' }}>
            Final Sale - No Returns or Exchanges
          </Link>
        </TextBottom>
      </CardContainer>
    </div>
  )
}

const cleanUpProduct = (product) => {
  if (!product) return {}
  const { stock: contextStock, storeLocale } = useContext(CartContext)
  const stock = contextStock[storeLocale] || {}
  const avaliableVariants = product.Variants?.reduce((accumulator, currentValue) => {
    if (
      stock[currentValue.SKU] &&
      stock[currentValue.SKU].avaliable === true &&
      stock[currentValue.SKU].on_site === true &&
      stock[currentValue.SKU].preorder === false
    ) {
      accumulator.push(currentValue)
    }
    return accumulator
  }, []).sort((a, b) => {
    const [hA] = rgbToHsv(...hexToRgb(a.Colour));
    const [hB] = rgbToHsv(...hexToRgb(b.Colour));

    // Adjust the hue values to start from red and wrap around the circle
    const adjustedHA = (hA + 0.93) % 1;
    const adjustedHB = (hB + 0.93) % 1;

    return adjustedHA - adjustedHB;
  });

  return {
    ...product,
    Variants: avaliableVariants
  }
}

const useShopPanelData = (data) => {
  const { stock: contextStock, storeLocale } = useContext(CartContext)
  let sharedData = data.shared

  let stock = JSON.parse(data?.stock?.skus || {})
  if (contextStock[storeLocale] != null) {
    stock = contextStock[storeLocale]
  }

  const filledTiles = sharedData?.UpsellModule?.map((items, index) => {
    let strapiProductVariants = items?.upsell_products?.[0]?.Variants?.map((variant) => ({
      ...variant,
    }))


    let filteredStrapiProductVariants = strapiProductVariants?.reduce((accumulator, currentValue) => {
      if (
        stock[currentValue.SKU] &&
        stock[currentValue.SKU].avaliable === true &&
        stock[currentValue.SKU].on_site === true &&
        stock[currentValue.SKU].preorder === false
      ) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, [])

    const item = { ...items.upsell_products?.[0], Variants: filteredStrapiProductVariants, discount: items?.discountAmount || 0 }
    const categories = items.product_category?.CategoryName

    return { item, categories }
  })
  return {
    upsellModuleData: filledTiles,
    sharedData: sharedData,
  }
}
const findUpsellProduct = (cart, upsellModuleData) => {
  let foundProduct = undefined
  let includesUpsell = false
  let cartUpsellOrder = cart?.map((cartItem) => cartItem.title)

  cart?.map((val) => {
    if (val.upsellItem == true) {
      includesUpsell = true
    }

    upsellModuleData?.map((upsell) => {
      val.product_categories?.map((item) => {
        if (
          !cartUpsellOrder.includes(upsell.item.Title) &&
          foundProduct === undefined &&
          item === upsell.categories &&
          upsell.item?.Variants?.length > 0
        ) {
          foundProduct = upsell.item
        }
      })
    })
  })
  if (includesUpsell == true) {
    return undefined
  }

  return foundProduct
}
const calculatePricing = (checkout) => {
  if (!checkout?.lineItems)
    return {
      sumPrice: 0,
      nonPerSubPriceList: 0,
    }
  let sumPrice = 0
  let nonPerSubPriceList = checkout?.lineItems.edges.map(({ node }) => {
    let lineItem = node
    let netPrice =
      (parseFloat(lineItem.variant?.price?.amount ? lineItem.variant?.price?.amount : '').toFixed(2) || 0) *
      lineItem.quantity
    if (lineItem['discountAllocations'] && lineItem['discountAllocations'].length > 0) {
      lineItem['discountAllocations'].map((discountAllocation) => {
        if (discountAllocation['discountApplication']['title'] === 'Free Personalisation') {
          netPrice += parseFloat(discountAllocation['allocatedAmount']['amount'])
        }
      })
    }
    if (lineItem.variant.sku.includes('freight')) {
      return 0
    }
    return netPrice
  })
  try {
    sumPrice = nonPerSubPriceList.reduce((accumulator, a) => accumulator + a, 0)
  } catch (e) {
    console.log(e)
  }
  return {
    sumPrice,
    nonPerSubPriceList,
  }
}

const UpsellModule = ({ data, sliceContext }) => {
  const storeLocale = sliceContext.locale
  const context = useContext(CartContext)
  const cart = context.cart
  const checkout = context.checkout

  const closeCart = () => {
    context.setCartOpen(false)
  }
  const {
    upsellModuleData,
    sharedData
  } = useShopPanelData(data)

  const [selectedUpsellProduct, setSelectedUpsellProduct] = useState({})
  const { sumPrice } = calculatePricing(checkout)

  useEffect(() => {
    setSelectedUpsellProduct(findUpsellProduct(cart, upsellModuleData))
  }, [cart])

  // const weekenderUpsellProducts = sharedData?.UpsellModule?.slice(0, 4)
  /* BUNDLE AND SAVE */
  // if (sharedData?.hideBundlePricing) {
  //   return (
  //     <>
  //       <CompleteText> <b> BUNDLE & SAVE </b> </CompleteText>
  //       <ProgressBar itemCount={cart?.map(val => [val.variantQuantity, val.id])} total={sumPrice} storeLocale={storeLocale} />
  //       <div style={{ display: checkout?.lineItems ? 'block' : 'none' }}>
  //         {(selectedUpsellProduct !== undefined && cart?.length > 0 && checkout?.subtotalPrice?.amount < 1000) && <>
  //           <CompleteText> Add more to your cart to access the savings. </CompleteText>
  //           <CartAddToCartModules
  //             refresh={!(checkout?.lineItems && checkout?.lineItems.length > 0)}
  //             closeCart={closeCart}
  //             product={selectedUpsellProduct}
  //             hideBundlePricing={sharedData?.hideBundlePricing || false}
  //             storeLocale={storeLocale}
  //           />
  //         </>}
  //       </div>
  //     </>
  //   )
  // }

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 600,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   adaptiveHeight: true,
  //   fade: false,
  //   prevArrow: <ArrowImage src={ArrowLeft} />,
  //   nextArrow: <ArrowImage src={ArrowRight} />,
  //   responsive: [
  //     {
  //       breakpoint: 1027,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //         arrows: true,
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         arrows: true,
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         arrows: true,
  //       }
  //     }
  //   ]
  // };

  // const hasLuggage = cart?.some(product =>
  //   product?.product_categories?.includes('Luggage')
  // );

  return (
    // <>
    //   {
    //     (checkout?.subtotalPrice?.amount > 150 && storeLocale !== 'en-GB' && storeLocale !== 'en' && selectedUpsellProduct !== undefined && cart.length > 0) ? (
    //       <UpsellGift
    //         refresh={!(checkout.lineItems && checkout.lineItems.length > 0)}
    //         closeCart={closeCart}
    //         product={sharedData?.UpsellModule[0]?.upsell_products[0]}
    //         hideBundlePricing={sharedData?.hideBundlePricing || false}
    //         discount={sharedData?.UpsellModule[0]?.discountAmount}
    //       />
    //     ) : (
    //       <div style={{ display: checkout.lineItems ? 'block' : 'none' }}>
    //         {(selectedUpsellProduct !== undefined && cart.length > 0) &&
    //           <CartAddToCartModules
    //             refresh={!(checkout.lineItems && checkout.lineItems.length > 0)}
    //             closeCart={closeCart}
    //             product={selectedUpsellProduct}
    //             hideBundlePricing={sharedData?.hideBundlePricing || false}
    //           />
    //         }
    //       </div>
    //     )
    //   }
    // </>
    <div style={{ display: checkout.lineItems ? 'block' : 'none' }}>
      {(selectedUpsellProduct !== undefined && cart.length > 0) &&
        <CartAddToCartModules
          refresh={!(checkout.lineItems && checkout.lineItems.length > 0)}
          closeCart={closeCart}
          product={selectedUpsellProduct}
          hideBundlePricing={sharedData?.hideBundlePricing || false}
          shared={data.shared}
        />
      }
    </div>
  )
}

export default UpsellModule

export const query = graphql`
  query ($locale: String) {
    shared: strapiShared (locale: { eq: $locale }) {
      UpsellModule {
        upsell_products {
          Title
          product_categories {
            CategoryName
          }
          shopifyInfo {
            ... on UKShopifyProduct {
              shopifyId
            }
            ... on NZShopifyProduct {
              shopifyId
            }
            ... on USShopifyProduct {
              shopifyId
            }
            ... on CAShopifyProduct {
              shopifyId
            }
            ... on AUShopifyProduct {
              shopifyId
            }
          }

          Variants {
            shopifyInfo {
              ... on UKShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on NZShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on USShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on CAShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
              ... on AUShopifyProductVariant {
                shopifyId
                price
                image {
                  gatsbyImageData(width: 200)
                }
              }
            }
            Colour
            ColourName
            SKU
            ShopTileImage {
              ...Image
            }
          }
          handle
          locale
        }
        product_category {
          CategoryName
        }
        discountAmount
      }
      locale
      SignUpId
      FreePersonalisation
      discountPromoOn
      FreeSuitcasePersonalisation
      hideBundlePricing
    }
    stock(locale: { eq: $locale }) {
      skus
    }
  }
`
